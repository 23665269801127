import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const BenefitsOf = () => {


  return (
    <section className="customs ethsmart pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits of</span> Ethereum Smart Contract Development
            </h2>
            <p className='text-center'>We create smart contracts on the Ethereum blockchain using streamlined techniques that combine Ethereum characteristics with automation and innovation. Check out the resultant benefits here.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Improved Security</h3>
                    <p class="pharagraph">We prefer innovative and latest technologies that can power smart contracts for security. Also, our smart contract development utilizes Ethereum’s infrastructural edges to develop tamper-proof smart contracts.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Streamlined Performance</h3>  
                    <p class="pharagraph">Our smart testing procedure has a dedicated auditing slot for verifying smart contract performance. The process will eradicate the functional lags and ensure top-notch smart contract performance.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Lower Fees</h3>
                    <p class="pharagraph">Pursuing innovations in development, our smart contract development compiles the edges of Ethereum 2.0 updates. So the smart contract can offer a quicker transaction at lower network fees in a systematic manner.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Trust and Transparency</h3>
                    <p class="pharagraph">Our Ethereum smart contracts are highly secure with blockchain characteristics and tamper-proof security protocols. As well as the transactions recorded in the smart contracts are also transparent.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Speed and Interoperability</h3>
                    <p class="pharagraph">We create smart contracts on Ethereum that utilize the maximum speed and performance at any cost. With flexible solidify coding, the Ethereum smart contract we create can interoperate with other networks easily.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Cost-Effectiveness</h3>
                    <p class="pharagraph">Ethereum smart contracts are automated functionalities that don’t need intermediary supervision. With refined functionalities, we create smart contracts on Ethereum contracts that meet your budget expectations.</p>
                </div>
            </div>
        </div>  
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default BenefitsOf