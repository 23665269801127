import React from 'react'



class HowTo extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use ethsmart pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor heading-h3'>How To Get Started</span> With Ethereum Smart Contract Development?</h2>
          <p className='text-center mw1030'>Creating Ethereum Smart contracts requires in-depth knowledge of blockchain technology. Coinscloine has a team of blockchain experts with vast experience in various Ethereum blockchain projects. Explore our competence that is reflected in our Ethereum smart contract development.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Expertise in Blockchain Technology
                </h3>
                <p className="pharagraph">With ample experience in blockchain development, our experts are well-skilled in Ethereum smart contract development services. Leverage the best of customized functionalities to your smart contract with our blockchain expertise.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Client-Centric Development Process
                </h3>
                <p className="pharagraph">Coinsclone always focuses on achieving clients’ productivity expectations with the bare minimum of possibilities. Our client-centric smart contract development process will offer you comfort in pricing, functionalities, and trust.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Proficiency in Solidity Programming
                </h3>
                <p className="pharagraph mb-lg-0">Dive into the world of automation by coding the smart contract functionalities aligned to your project ideas. Our blockchain expert’s skill set in Solidity programming will make the Ethereum smart contract development more appealing to your business standards.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Testing Codes
                </h3>
                <p className="pharagraph mb-lg-0">We conduct multiple phases of testing to ensure the proper functionality of smart contracts. We have a well-structured testing environment and a separate team of test engineers who audit and optimize the smart contract coding for hassle-free operations.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HowTo