import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Ethereum Smart</span> Contract Development By Coinsclone</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/eth-smart/ethereum-developed.webp"
                alt="Why Choose Ethereum smart contract"
                placeholder='none'
                className='mt-3 mb-3'
                width={437}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone is a leading Ethereum smart contract development company mastering blockchain-based development services. Our blockchain professionals are greatly exposed to industrial trends and experienced in creating smart contracts in various blockchains. We follow refined client-friendly development services prioritizing your enterprise ideas and goals. With in-depth solidity language, we create smart contracts on Ethereum which is future-ready and performance-rich ideal for diverse industries.
              </p>
              <p className="pharagraph">As a renowned Ethereum smart contract development company, we achieve clients’ business goals with technical fluency. With more than 6+ years of industry experience, our blockchain development services have been the reason for many thriving businesses. So get your queries, requirements, and business goals consulted with our experts.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose