import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/eth-smart/Banner';
import Whatis from '../components/eth-smart/Whatis';
import FaqSection from '../components/eth-smart/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import TechStack from '../components/eth-smart/TechStack';
import DevProcess from '../components/eth-smart/DevProcess';
import WhyChoose from '../components/eth-smart/WhyChoose';
import HowTo from '../components/eth-smart/HowTo';
import BenefitsOf from '../components/eth-smart/BenefitsOf';
import IndustriesEvolv from '../components/eth-smart/IndustriesEvolv';


const EthereumSmart = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/ethereum-smart-contract-development/" />
        <title>Ethereum Smart Contract Development - Coinsclone</title>
        <meta name="description" content="Ethereum smart contract development involves a step-by-step procedure to create, test, and deploy smart contract on Ethereum blockchain for business services." />
        <meta name="keywords" content="Ethereum Smart Contract Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ethereum Smart Contract Development - Coinsclone" />
        <meta property="og:description" content="Ethereum smart contract development involves a step-by-step procedure to create, test, and deploy smart contract on Ethereum blockchain for business services." />
        <meta property="og:url" content="https://www.coinsclone.com/ethereum-smart-contract-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/eth-smart/ethereum-smart-contract-development.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Ethereum smart contract development involves a step-by-step procedure to create, test, and deploy smart contract on Ethereum blockchain for business services." />
        <meta name="twitter:title" content="Ethereum Smart Contract Development - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/eth-smart/ethereum-smart-contract-development.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Ethereum Smart Contract Development</span>
        </div>
      </div>
      <Whatis />
      <HowTo />
      <DevProcess />
      <BenefitsOf />
      <IndustriesEvolv />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default EthereumSmart
