import React from "react"

class DevProcess extends React.Component {

  render() {
    return (

      <section className="fine-box ethsmart pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Ethereum Smart Contract <span className="bluecolor">Development Process</span></h2>
            <p className="text-center mb-2">
            Level up the standard flow of business routine with Ethereum smart contract creation that simplifies, signifies, and standardizes your service offerings. Explore the step-by-step development methods here.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Requirement Gathering</h3>
                <p className="pharagraph">
                First, our expert team listens and analyzes your business purpose and defines the requirements for Ethereum smart contract development. Also, we examine the functionalities of smart contracts and prepare a roadmap for development.
                </p>
              </div>
              <div className="square" >
                <h3>Preparing a Crypto Wallet</h3>
                <p className="pharagraph">
                With a wider knowledge of smart contract development, we suggest the best crypto wallets for smart contract processing. Our blockchain experts will suggest the wallet’s test networks for checking contract features.
                </p>
              </div>
              <div className="square" >
                <h3>Write smart contract coding</h3>
                <p className="pharagraph">
                Our skilled professionals will prepare the smart contract coding complying with the service offerings. The process involves integrating the necessary parameters into the smart contract feature set.
                </p>
              </div>
              <div className="square" >
                <h3>Testing the Codes</h3>
                <p className="pharagraph">
                The smart contract’s functionality will be completely examined by our test engineers. The thorough verification will bring you hassle-free and quicker smart contract execution without functional drawbacks.
                </p>
              </div>
              <div className="square" >
                <h3>Deployment</h3>
                <p className="pharagraph">
                Our solidity architects will deploy the smart contract in the Mainnet of the Ethereum blockchain. Again we also ensure the smart contract is safely deployed and worked according to your business operations.
                </p>
              </div>
              <div className="square" >
                <h3>Upgrades and Monitoring</h3>
                <p className="pharagraph">
                We offer top-notch monitoring services as part of the Ethereum smart contract development. The necessary upgrades and reminders for updates will be shared with you on time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevProcess
