import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Ethereum Smart Contract</span> Development</h1>
                <p className='pharagraph mb-0'>Embrace the speed, automation, and trustworthiness of your crypto business with our Ethereum smart contract development services. At Coinsclone, we aid you in transforming the users’ demands into services with fine-tuned, multi-tested, and customized Ethereum smart contracts.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/eth-smart/ethereum-smart-contract-development.webp"
                alt="Ethereum Smart Contract development"
                className='d-none d-md-block float-end'
                width={530}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection