import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Ethereum - Blockchain </span>Platform for Creating a Smart Contract</h2>
              <p className="pharagraph">Ethereum, one of the prominent blockchains has been the powering element of various industries. With robust network architecture, Ethereum smart contracts are adaptable to a myriad of business operations. Our Ethereum smart contract development redefines the smart contract capabilities for refined transactions, speed, and security. Explore the different industries where Ethereum smart contracts’ roles are crucial.</p>
              <p className="pharagraph">Coinsclone’s blockchain experts are acquainted with solid programming and are skilled in developing Ethereum smart contracts as innovative and feature-rich to meet your business goals. The components that make the Ethereum blockchain ideal for smart contract development are,</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/eth-smart/ethereum-smart-contract.webp"
                alt="Ethereum Blockchain Platform"
                placeholder='none'
                className='mt-3 mb-3'
                width={511}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph"><b>Ethereum Virtual Machine (EVM) :</b> The computation component that takes care of the process of Ethereum blockchains in a regulated manner.
              </p>
              <p className="pharagraph"><b>Gas Fees :</b> The fees to conduct or execute transactions in the Ethereum blockchain network that usually range in a certain percentage.
              </p>
              <p className="pharagraph"><b>Consensus Algorithm :</b> Consensus algorithm is the mechanism that validates the authenticity of transactions in a blockchain.
              </p>
              <p className="pharagraph"><b>Solidity language :</b> It is the programming language used to code smart contract roles and functionality in a defined manner.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis